import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();

  const filters = {
    DESIGN: "Design",
    AI: "AI",
    PROJECTS: "Project",
  };

  const projectsData = [
    {
      title: "Supershot paintball",
      projectInfo:
        "I worked on a freelance project for a paintball company called Supershot in Laois. I optimized for search engines. I built the website, using programming languages such as HTML, CSS, and JavaScript. I ensure that my website is responsive (meaning it works on different devices and screen sizes), and that it is optimized for speed and performance. Once was finished, I tested it to ensure that it worked properly and is free from bugs and errors. This may involve manual testing. When I was happy with my website I launched it! I made it live on the internet and promote it to the clients target audience. However, building a website is an ongoing process. I need to update my website regularly with fresh content, fix any bugs or errors that arised.",
      client: "Supershot paintball",
      technologies: "HTML5, CSS3, PHP, Bootstrap, jQuery",
      industry: "Sports & Leisure",
      date: "Oct 14, 2015",
      url: {
        name: "No longer available",
        link: "https://www.tripadvisor.com/Attraction_Review-g1592328-d4701238-Reviews-Supershot_Paintball-Stradbally_County_Laois.html",
      },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Supershot.PNG",
      sliderImages: [
        "images/projects/Supershot2.PNG",
        "images/projects/Supershot3.PNG",
      ],
      categories: [filters.DESIGN],
    },
    {
      title: "Distributed Development patterns",
      projectInfo:
        "In this project I learned different distibuted patterns such as Signalling with Semaphores, Mutual Exclusion, Barrier Patterns, Producers and Consumers, Rendezvous for solving complex multi threading problems, I also learned how to link and complile c++ files for the inpurter without the use of an IDE and learned how to generate doucmentation using Doxygen",
      client: "South East technological University",
      technologies: "Git, Emacs, g++, gdb, Make",
      industry: "Academic",
      date: "October 5, 2019",
      url: {
        name: "Detailed requiremnets",
        link: "https://github.com/conleth/MutiThreadingLabs/blob/main/labs/LabSheet.pdf",
      },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:conlethkennedy@gmail.com",
      // },
      thumbImage: "images/projects/DD.PNG",
      sliderImages: [
      ],
      categories: [filters.PROJECTS],
    },
    {
      title: "Flannery Building Services",
      projectInfo:
        "I created a website for Flannery Building Services, which provides construction services for commercial and residential clients. The website includes information about the company's services, experience, and projects. It also features a contact page where potential clients can get in touch with the company.",
      client: "Pat Flannery",
      technologies: "HTML5, CSS3, PHP, jQuery js, Bootstrap, gulp",
      industry: "Constrcution",
      date: "Jan 12, 2017",
      url: {
        name: "www.flannery.ie",
        link: "https://vimeo.com/8621100?embedded=true&source=vimeo_logo&owner=2934563",
      },
      socialLinks: {
        facebook: "https://www.facebook.com/Flannery.Joinery.and.Building/",
        mail: "mailto:flannerybuildingservices@hotmail.com",
      },
      thumbImage: "images/projects/flannery.png",
      sliderImages: [
        "images/projects/flannery2.png",
        "images/projects/flannery3.png",
      ],
      categories: [filters.DESIGN],
    },
    {
      title: "Exploring Integration Methods in Modern Software Ecosystems",
      projectInfo:
        "I explored integration methods in modern software ecosystems, evaluated their performance, scalability, and reliability, and compared their implementation. I analyzed the advantages and limitations of each method, evaluated them in live software ecosystems, and documented my findings. I provided recommendations for the best integration methods to use based on their performance and scalability analysis. 40k Document work count and 40k lines of code working scalable production code. Achieving 2nd highest mark that year.",
      client: "South East technological University",
      technologies: "IBM IIB, Qlikview, IBM MQ, Saleforce, BizTalk, Teradata",
      industry: "Academic",
      date: "May 10, 2020",
      url: {
        name: "Cloud and System Itergration",
        link: "https://dev.itcarlow.ie/C00172853/",
      },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:conlethkennedy@gmail.com",
      // },
      thumbImage: "images/projects/sys-int2.png",
      sliderImages: [
        "images/projects/sys-int.png",
        "images/projects/sys-int1.png",
      ],
      categories: [filters.PROJECTS],
    },

    {
      title: "Project Maverick",
      projectInfo:
        "In Unum’s innovation team in a hands-on/consultancy role developing microservices architecture for an AI project. Team was comprised of 8 members 2 in Ireland, 4 in the US and 2 in India. We also worked with a company who provided the data science expertise who has a PHD in data science from MIT. I worked very closely with the principal engineer. The goal was semi-automated the STD claims process and cut claims processing from minutes from day. The AI was able to make an accurate decision 84% of the time within less than 1 minute. Winning internal CEO award and  Most advanced AI-based insurance claim adjudication engine externally",
      client: "Unum Group",
      technologies: "C#, React, Typescript ,PSQL, Bash, Python, AWS, Node JS, J-frog, Mongo DB, Mulesoft",
      industry: "Insurance",
      date: "October, 27 2020",
      url: {
        name: "Unum",
        link: "https://www.unum.com/",
      },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:conlethkennedy@gmail.com",
      // },
      thumbImage: "images/projects/maverick.jpeg",
      sliderImages: [

      ],
      categories: [filters.AI],
    },
    // {
    //   title: "Project Title 5",
    //   projectInfo:
    //     "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    //   client: "Ruby Clinton",
    //   technologies: "iOS, HTML5, CSS3, PHP, Java",
    //   industry: "Art & Design",
    //   date: "July 16, 2019",
    //   url: {
    //     name: "www.example.com",
    //     link: "https://www.example.com",
    //   },
    //   socialLinks: {
    //     facebook: "http://www.facebook.com/",
    //     twitter: "http://www.twitter.com/",
    //     google: "http://www.google.com/",
    //     instagram: "http://www.instagram.com/",
    //     mail: "mailto:example@gmail.com",
    //   },
    //   thumbImage: "images/projects/project-5.jpg",
    //   sliderImages: [
    //     "images/projects/project-1.jpg",
    //     "images/projects/project-5.jpg",
    //   ],
    //   categories: [filters.DESIGN],
    // },
    // {
    //   title: "Project Title 6",
    //   projectInfo:
    //     "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    //   client: "Ruby Clinton",
    //   technologies: "iOS, HTML5, CSS3, PHP, Java",
    //   industry: "Art & Design",
    //   date: "July 16, 2019",
    //   url: {
    //     name: "www.example.com",
    //     link: "https://www.example.com",
    //   },
    //   socialLinks: {
    //     facebook: "http://www.facebook.com/",
    //     twitter: "http://www.twitter.com/",
    //     google: "http://www.google.com/",
    //     instagram: "http://www.instagram.com/",
    //     mail: "mailto:example@gmail.com",
    //   },
    //   thumbImage: "images/projects/project-6.jpg",
    //   sliderImages: [
    //     "images/projects/project-1.jpg",
    //     "images/projects/project-5.jpg",
    //   ],
    //   categories: [filters.AI],
    // },
    // {
    //   title: "Project Title 7",
    //   projectInfo:
    //     "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    //   client: "Ruby Clinton",
    //   technologies: "iOS, HTML5, CSS3, PHP, Java",
    //   industry: "Art & Design",
    //   date: "July 16, 2019",
    //   url: {
    //     name: "www.example.com",
    //     link: "https://www.example.com",
    //   },
    //   socialLinks: {
    //     facebook: "http://www.facebook.com/",
    //     twitter: "http://www.twitter.com/",
    //     google: "http://www.google.com/",
    //     instagram: "http://www.instagram.com/",
    //     mail: "mailto:example@gmail.com",
    //   },
    //   thumbImage: "images/projects/project-7.jpg",
    //   sliderImages: [
    //     "images/projects/project-1.jpg",
    //     "images/projects/project-5.jpg",
    //   ],
    //   categories: [filters.DESIGN, filters.PROJECTS],
    // },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              Portfolio
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              My Work
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="portfolio-overlay">
                          <a
                            className="popup-ajax stretched-link"
                            href="/"
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            {/* Non-breaking space as content */}
                            &nbsp;
                          </a>

                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                            <span className="text-light">Category</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;
