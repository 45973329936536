import axios from 'axios';

export async function generateResume(jobDescription, skillsArray) {
    const apiUrl = `${process.env.REACT_APP_BACKEND_HOST_URL}/api/v1/customResume`;
  
    try {
      const response = await axios.post(apiUrl, {
        text: jobDescription,
        // skills: skillsArray
      }, {
        responseType: 'blob' // Important for file downloads
      });
  
      if (response.status === 200) {
        return response.data; // Assuming the API returns the generated resume data
      } else {
        console.error('Failed to generate resume:', response.statusText);
        return null;
      }
    } catch (error) {
      console.error('Error generating resume:', error);
      return null;
    }
  }