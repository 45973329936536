import resumeFile from "../documents/2023NewCV.docx";
import React, { useEffect, useState } from 'react';
import { getSkills } from '../services/getSkills';
import { generateResume } from '../services/generateResume';
import DescriptionBox from './DescriptionBox';
import './grid.css'; // Import your CSS file for styling
import { CircleLoader } from 'react-spinners';

const Resume = ({ classicHeader, darkTheme }) => {

  //My skills from my database
  const [apiSkills, setApiSkills] = useState([]);
  //The skills parsed from the job description
  const [extractedSkills, setExtractedSkills] = useState([]);
  //The skills that are common to both arrays
  // const [commonSkills, setCommonSkills] = useState([]);
  const [isGeneratingResume, setIsGeneratingResume] = useState(false);

  // const [compared, setCompared] = useState(false);
  const [jobDescText, setJobDescText] = useState('');
  const [jobDescSent, setJobDescSent] = useState(false);
  const [match, setMatch] = useState(false);

  // Fetch skills from different endpoints on component mount
  useEffect(() => {
    const fetchApiSkills = async () => {
      const data = await getSkills();
      if (data) setApiSkills(data);
    };

    fetchApiSkills();
    // fetchExtractedSkills();
  }, []);


  useEffect(() => {
    if (apiSkills && extractedSkills.skills && jobDescSent) {
      const mySkills = apiSkills.map(skill => skill.skill);
      const exList = extractedSkills.skills.map(skill => skill);
  
      // Compare the skill names from both arrays
      const commonSkills = exList.filter(skill => mySkills.includes(skill));
      //TODO: Change this to 50% of the skills after testing just assume true for now
      
      // if(commonSkills.length >= 1){
      if(commonSkills.length >= exList.length / 2){
        setMatch(true);
      } else {
        setMatch(true);
      }
    }
  }, [jobDescSent,apiSkills, extractedSkills.skills]); // Ensure this effect runs only when jobDescSent changes
  

  // Compare skills from both endpoints
  // const compareSkills = () => {
  //   setCompared(true);
  //   const extractedSkillNames = apiSkills.map(skill => skill.skill);
  //   console.log(extractedSkills);
  //   const exList = extractedSkills.skills.map(skill => skill);
  //   console.log(exList);
  //   // Compare the skill names from both arrays
  //   const commonSkills = exList.filter(skill => extractedSkillNames.includes(skill));
  //   setCommonSkills(commonSkills);
  // };

  const generateCustomResume = () => {
    setIsGeneratingResume(true);
    let description = jobDescText
    let skills = ['Java', 'Python', 'C#', 'React', 'Node', 'Express', 'MongoDB', 'Postgres', 'AWS', 'Azure', 'Docker', 'YAML', 'Selenium', 'PowerShell', 'Visual Studio', 'IBM Websphere', 'Salesforce', 'Ruby', 'MuleSoft', 'Azure Marketplace', 'Dynamo DB'];
    skills[0].charAt(1)
    console.log("Generating custom resume");
    generateResume(description).then(data => {
      if (data) {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'CustomResume.docx'); // or '.docx' depending on your file type
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          setIsGeneratingResume(false);
      }
    }).catch(error => {
        setIsGeneratingResume(false);
        console.error('Error while generating and downloading resume:', error);
    });
    // window.open('https://resume-builder-frontend.herokuapp.com/', '_blank');
  }


  const educationDetails = [
    {
      yearRange: "2012 - 2015",
      title: "Bachelor's Degree in Computer Software Engineering",
      place: "Institute of Technology, Carlow",
      desc: "Graduated with a 2.1 grade.",
    },
    {
      yearRange: "2018 - 2020",
      title: "Honours Bachelor of Science in Computer Science",
      place: "Institute of Technology, Carlow",
      desc: "Graduated with a 2.1 grade.",
    },
  ];

  // const experienceDetails = [
  //   {
  //     yearRange: "2012 - 2013",
  //     title: "Jr. UI UX Designer",
  //     place: "Themeforest",
  //     desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
  //   },
  //   {
  //     yearRange: "2014 - 2016",
  //     title: "Jr. Product Designer",
  //     place: "Dribbble",
  //     desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
  //   },
  //   {
  //     yearRange: "2017 - 2019",
  //     title: "Product Designer",
  //     place: "Adobe",
  //     desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
  //   },
  // ];

  const experienceDetails = [
    {
      yearRange: "May 2022 - Present",
      title: "Technical Lead/Manager",
      place: "Forward Security",
      desc: "Oversaw design, product, marketing, process, and user experience decisions with CEO. Managed company infrastructure and user access. Delegated tasks, worked with strategic partners, and mentored team members. Led meetings and supported workshops, as well as demoed to clients and prioritized backlogs. Built out CI/CD maturity and led production releases while defining branching strategy.",
    },
    {
      yearRange: "March 2015 - May 2022",
      title: "Full Stack Developer II",
      place: "Unum Ireland",
      desc: "As a lead developer in Unum’s innovation team, I developed microservices architecture for an AI project with a team of 8 across 3 countries. Our goal was to semi-automate the STD claims process, reducing claims processing time to less than a minute with 84% accuracy. I also worked on a temporary dev ops role delivering a vaccination tracker for the OSHA bill passed in the US, focusing mainly on backend development.",
    }
  ];

  const skills = [
    {
      name: "Rest API's",
      percent: 90,
    },
    {
      name: "Secuirty Scanning",
      percent: 99,
    },
    {
      name: "Web Design",
      percent: 85,
    },
    {
      name: "Secuirty",
      percent: 85,
    },
    {
      name: "Bootstrap",
      percent: 99,
    },
    {
      name: "Node Js",
      percent: 75,
    },
    {
      name: "C#",
      percent: 80,
    },
    {
      name: "Selenium",
      percent: 99,
    },

    {
      name: "Postgres",
      percent: 85,
    },
    {
      name: "Dev Ops",
      percent: 99,
    },

    {
      name: "Docker",
      percent: 80,
    },
    {
      name: "YAML",
      percent: 99,
    },
    {
      name: "AWS",
      percent: 75,
    },
    {
      name: "Azure",
      percent: 90,
    },
    {
      name: "React JS",
      percent: 80,
    },
    {
      name: "Dynamo DB",
      percent: 75,
    },
    {
      name: "Visual Studio",
      percent: 70,
    },
    {
      name: "IBM Websphere",
      percent: 70,
    },

    {
      name: "Java",
      percent: 75,
    },
    {
      name: "Salesforce",
      percent: 70,
    },
    {
      name: "PowerShell",
      percent: 85,
    },
    {
      name: "Ruby",
      percent: 60,
    },
    {
      name: "Python",
      percent: 60,
    },
    {
      name: "Mongo DB",
      percent: 65,
    },
    {
      name: "MuleSoft",
      percent: 50,
    },
    {
      name: "Azure Marketplace",
      percent: 75,
    },
  ];


  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Education */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Education
            </h2>
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
          {/* My Experience */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Experience
            </h2>
            {experienceDetails.length > 0 &&
              experienceDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
        </div>
        {/* My Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          My Skills
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            Download CV
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div>
        <div className="row gx-5">
          {/* Button to trigger comparison */}


          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              Generator
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              Custom Resume Builder
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>

          <DescriptionBox setExtractedSkills={setExtractedSkills} setJobDescSent={setJobDescSent} setJobDescText={setJobDescText} classicHeader={classicHeader} darkTheme={darkTheme} />


          {/* Display compared skills as a list */}

          {jobDescSent && (
            <div>
              <h2 className={"text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")}>
                AI Analysis
              </h2>
              {match ? (
                <div>
                  <p className={"text-4 fw-200 mb-2 " + (darkTheme ? "text-white" : "")}>
                    Looks like Conleth is a good match for this job.
                  </p>
                  {/* <ul>
                    {commonSkills.map((skill, index) => (
                      <li key={index}>{skill}</li>
                    ))}
                  </ul> */}
                  {isGeneratingResume ? (
                    <button className="btn btn-primary rounded-pill" >
                    Downloading Resume ...
                    <CircleLoader color="#FFFFFF" loading={true} size={20} />
                    </button>
                  ) : (
                    <button className="btn btn-primary rounded-pill" onClick={generateCustomResume}>
                    Download Custom Resume
                  </button>
                  )}
                </div>
              ) : (
                <>
                  <p className={"text-4 fw-200 mb-2 " + (darkTheme ? "text-white" : "")}>
                    Looks like Conleth is not a good fit for this job. Please feel free to try again in the future!
                  </p>
                </>
              )}
            </div>
          )}

        </div>
      </div>



    </section>
  );
};

export default Resume;
