import React, { useState } from 'react';
import { extractSkillsFromJobDescription } from '../services/getSkills';



function DescriptionBox({ setExtractedSkills, setJobDescSent ,setJobDescText , classicHeader, darkTheme }) {
    const [text, setText] = useState('');
    //   const [extractedSkills, setInnerExtractedSkills] = useState([]);
    const handleSubmit = async (event) => {
        event.preventDefault();
        // const jobDescription = "Your job description goes here";
        const jobDescription = text;

        const fetchExtractedSkills = async () => {
            const extracted = await extractSkillsFromJobDescription(jobDescription);
            if (extracted) setExtractedSkills(extracted);
            return extracted;
        };

        await fetchExtractedSkills();
        // setInnerExtractedSkills(skills);
        // setExtractedSkills(skills);
        setJobDescSent(true);
        setJobDescText(jobDescription);
        // handle the response
    };

    return (
        <section
            id="ResumeBuilder"
            className={"section " + (darkTheme ? "bg-dark-1" : "")}
        >
            <form onSubmit={handleSubmit}>
                <div className="col">
                    <textarea
                        name="message"
                        className="form-control"
                        rows={5}
                        required
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        placeholder="Please paste your job description here........"
                    />
                </div>
                <br />
                <div className="text-center">
                    <button
                        id="submit-btn"
                        className="btn btn-primary rounded-pill d-inline-flex mx-auto"
                        type="submit"
                    >
                        <>Send Job Description</>
                    </button>
                </div>
            </form>
        </section>
    );
}

export default DescriptionBox;