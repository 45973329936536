import axios from 'axios';

export async function getSkills() {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_HOST_URL}/api/v1/skills`);
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching data: ", error);
    return null;
  }
}

export async function extractSkillsFromJobDescription(jobDescription) {
  const apiUrl = `${process.env.REACT_APP_BACKEND_HOST_URL}/api/v1/extractSkills`; // Replace with your ChatGPT API endpoint

  try {
    const response = await axios.post(apiUrl, {
      text: jobDescription
    });

    if (response.status === 200) {
      return response.data; // Assuming the API response contains a 'skills' field with the extracted skills
    } else {
      console.error('Failed to extract skills:', response.statusText);
      return [];
    }
  } catch (error) {
    console.error('Error extracting skills:', error);
    return [];
  }
}
